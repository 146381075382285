import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { useInsightsAnalytics } from '../../../../features/insights/hooks/useInsightsAnalytics';
import { AccountSelector } from '../../../accounts2/components/AccountSelector';
import { AccountFilterRender } from './AccountFilterRender';

export const AccountFilter = () => {
  const { accounts, selectAccounts } = useCustomerIntelligenceFilters();
  const { portfolio } = useInsightsAnalytics();

  return (
    <AccountSelector
      onSelectionClear={() => selectAccounts([])}
      onSelect={id => selectAccounts([{ id, provider: 'ble', name: 'ble' }])}
    />
  );
};
