import React, { useEffect, useState } from 'react';
import { Content, FilterSpacer, Shadow } from './style';
import { Button, Input, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { UpcomingMeetingsFilter } from '../UpcomingMeetingsFilter';
import { useUpcomingMeetings } from '../../../meetings/upcoming-meetings/upcoming-meetings.hook';

const { Search } = Input;

const UpcomingMeetingsFilterBar: React.FC = () => {
  const { searchTerm, isFetching, refetch, changeSearchTerm, resetFilters } = useUpcomingMeetings();

  const [inputSearchTerm, setInputSearchTerm] = useState('');

  useEffect(() => {
    setInputSearchTerm(searchTerm);
  }, [searchTerm]);

  const handleChangeInputText = (text: string) => {
    setInputSearchTerm(text);
    changeSearchTerm(text);
  };

  const handleClearAllFilters = () => {
    // clearing local states
    setInputSearchTerm('');

    // clearing search
    changeSearchTerm('');

    // Reset all other filters
    resetFilters();

    // refetchdata
    refetch();
  };

  return (
    <Content>
      <FilterSpacer>
        <Shadow>
          <Search
            placeholder="Search by title, account, or participants"
            onSearch={value => changeSearchTerm(value)}
            style={{ width: 350 }}
            value={inputSearchTerm}
            onChange={ev => handleChangeInputText(ev.target.value)}
          />
          <UpcomingMeetingsFilter />
        </Shadow>
        <Space size={'large'}>
          <Button disabled={isFetching} onClick={handleClearAllFilters} icon={<ReloadOutlined />}>
            Clear Filters
          </Button>
        </Space>
      </FilterSpacer>
    </Content>
  );
};

export default UpcomingMeetingsFilterBar;
