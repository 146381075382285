import React, { useEffect, useState } from 'react';
import { Content, FilterSpacer, Shadow } from './style';
import { Button, Input, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { DateSelector } from '../../../shared/components/DateSelector';
import { PastMeetingsFilter } from '../PastMeetingsFilter';
import { usePastMeetings } from '../../../meetings/past-meetings/past-meetings.hook';

const { Search } = Input;

const PastMeetingsFilterBar: React.FC = () => {
  const {
    searchTerm,
    isFetching,
    dateRange,
    refetch,
    changeSearchTerm,
    changeDateRange,
    resetFilters,
  } = usePastMeetings();

  const [inputSearchTerm, setInputSearchTerm] = useState('');
  const [dateOption, setDateOption] = useState<string>('30d');

  useEffect(() => {
    setInputSearchTerm(searchTerm);
  }, [searchTerm]);

  const handleChangeInputText = (text: string) => {
    setInputSearchTerm(text);
    changeSearchTerm(text);
  };

  const handleClearAllFilters = () => {
    // clearing local states
    setInputSearchTerm('');
    setDateOption('30d');

    // clearing search
    changeSearchTerm('');

    // Reset the date range
    changeDateRange([null, null]);

    // Reset all other filters
    resetFilters();

    // refetchdata
    refetch();
  };

  return (
    <Content>
      <FilterSpacer>
        <Shadow>
          <Search
            placeholder="Search by title, account, or participants"
            onSearch={value => changeSearchTerm(value)}
            style={{ width: 350 }}
            value={inputSearchTerm}
            onChange={ev => handleChangeInputText(ev.target.value)}
          />

          <DateSelector
            direction={'past'}
            value={dateOption}
            onDateOptionChanged={setDateOption}
            onDateRangeChanged={changeDateRange}
            predefinedRanges={{
              '3m': false,
              '6m': false,
              '12m': false,
            }}
            defaultValues={dateRange}
          />

          <PastMeetingsFilter />
        </Shadow>

        <Space size={'large'}>
          <Button disabled={isFetching} onClick={handleClearAllFilters} icon={<ReloadOutlined />}>
            Clear Filters
          </Button>
        </Space>
      </FilterSpacer>
    </Content>
  );
};

export default PastMeetingsFilterBar;
