import React, { ReactElement, useEffect, useState } from 'react';
import { useCrmFilterValues } from '../../../../features/crm-integration/hooks/use-crm-filter-values';
import { useCrmIntegration } from '../../../../features/crm-integration/hooks/use-crm-integration';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { SelectAll } from '../SelectAll';

export const CRMFilters: React.FC = () => {
  const { filters: crmFilters } = useCrmFilterValues();
  const { workspaceCrmIntegration } = useCrmIntegration();
  const { setCrmMap } = useCustomerIntelligenceFilters();

  const [filterSelections, setFilterSelections] = useState<Map<string, string[]>>(new Map());
  const [components, setComponents] = useState<ReactElement[]>([]);

  useEffect(() => {
    const newComponents: ReactElement[] = [];

    for (const filt in crmFilters) {
      const property = workspaceCrmIntegration?.workspace.properties.find(p => p.id === filt);
      const label = property?.label || filt;

      // Convert the string array to the option format expected by SelectAll
      const options = crmFilters[filt].map(value => ({
        id: value,
        label: value,
      }));

      newComponents.push(
        <SelectAll
          key={filt}
          showAll
          style={{ width: '220px', maxHeight: '32px' }}
          label={label}
          value={filterSelections.get(filt) || []}
          options={options}
          onSelectionChanged={(selectedValues: string[]) => {
            const newSelections = new Map(filterSelections);
            if (selectedValues.length > 0) {
              newSelections.set(filt, selectedValues);
            } else {
              newSelections.delete(filt);
            }
            setFilterSelections(newSelections);
            setCrmMap(newSelections);
          }}
        />,
      );
    }

    setComponents(newComponents);
  }, [workspaceCrmIntegration, crmFilters, filterSelections]);

  return <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>{components}</div>;
};
