import { Badge, Button, Divider, Switch, Tooltip } from 'antd';
import { Event } from '../..';
import { colors } from '../../../../../../../theme/colors';
import { differenceInMinutes, format } from 'date-fns';
import { Avatar as AntAvatar } from 'antd';
import { StarsIcn } from '../../../../../../shared/components/Icons/StarIcon';
import { coreService } from '../../../../../../../services/core/core-service';
import { useState } from 'react';
import { AccountIcon } from '../../../../../../shared/components/AccountIcon';
import { useToast } from '../../../../../../../hooks/useToast';
import Avatar from '../../../../../../shared/components/Avatar';

import Styles from './styles';
const { Container, Detail, Section } = Styles;

interface Props {
  event: Event;
  onInsightToggleChanged?: (checked: boolean) => void;
}

export const MeetingPopover: React.FC<Props> = ({ event, onInsightToggleChanged }) => {
  const [loading, setLoading] = useState(false);
  const { error, success } = useToast();

  const participantsEmails = (event.participants || [])
    .map(participant => participant.email)
    .sort((a, b) => {
      return a.localeCompare(b);
    });

  const participantsTooltip = (
    <>
      <strong>{'Meeting participants\n'}</strong>
      {[...participantsEmails].join('\n')}
    </>
  );

  const shownEmails = participantsEmails.slice(0, 4);
  const exceedingMembers = participantsEmails.length - 4;
  const hasMoreMembers = participantsEmails.length > 4;
  const preText = event.excludedFromInsights
    ? `Insights from this meeting recap are currently being excluded from `
    : `Insights from this meeting recap will be included in `;

  const divider = <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />;

  const handleInsightToggleChanged = async (checked: boolean) => {
    if (!onInsightToggleChanged) {
      return;
    }

    const oldValue = !checked;

    setLoading(true);
    try {
      onInsightToggleChanged(checked);
      if (checked) {
        await coreService.excludeRecapFromInsights(event.id);
      } else {
        await coreService.includeRecapFromInsights(event.id);
      }
      success('Insight setting updated');
    } catch {
      error('Error while updating insight setting');
      onInsightToggleChanged(oldValue);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Badge
        style={{ backgroundColor: colors.blue[600], borderRadius: '5px' }}
        count={event.participantsType === 'internal' ? 'Internal Meeting' : 'External Meeting'}
      />

      <Section
        style={{
          marginTop: '20px',
        }}
      >
        <h1>{event.title}</h1>
        <h2>
          {format(new Date(event.scheduledStartDate || ''), 'eeee MMM dd, hh:mm aa').toUpperCase()}{' '}
          TO {format(new Date(event.scheduledEndDate || ''), 'hh:mm aa').toUpperCase()}
        </h2>
      </Section>

      {divider}

      <Section>
        <Detail>
          <h3>Owner</h3>
          <span>{event.ownerName || event.owner || 'No Host'}</span>
        </Detail>

        <Detail>
          <h3>Host</h3>
          <span>{event.host || 'No Host'}</span>
        </Detail>

        <Detail>
          <h3>Duration</h3>
          <span>
            {differenceInMinutes(
              new Date(event.ended || event.scheduledEndDate),
              new Date(event.started || event.scheduledStartDate),
            ) + ' minutes' || 'No Host'}
          </span>
        </Detail>

        <Tooltip title={participantsTooltip} placement="right">
          <Detail>
            <h3>Participants</h3>

            <AntAvatar.Group>
              {shownEmails.map(email => (
                <Avatar color="#566976" background={colors.coolGray[100]} key={email} size="small">
                  {email[0].toUpperCase()}
                </Avatar>
              ))}

              {hasMoreMembers ? (
                <Avatar color="#566976" background={colors.coolGray[100]} size="small">
                  +{exceedingMembers}
                </Avatar>
              ) : null}
            </AntAvatar.Group>
          </Detail>
        </Tooltip>

        <Detail>
          <h3>Account(s)</h3>

          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {event.account && (
              <AccountIcon
                provider={event.account?.provider || ''}
                styles={{ maxHeight: '12px', maxWidth: '12px' }}
              />
            )}
            <span>{event.account?.name || 'No Account selected'}</span>
          </div>
        </Detail>

        {event.status === 'READY' && (
          <>
            {divider}

            <Detail>
              <h3 style={{ width: '70%' }}>
                {preText}
                <a
                  onClick={() => window.open(`${window.location.origin}/accounts`)}
                  style={{ color: colors.primary[500] }}
                >
                  Account Insights
                </a>{' '}
                and{' '}
                <a
                  onClick={() => window.open(`${window.location.origin}/insights`)}
                  style={{ color: colors.primary[500] }}
                >
                  Portfolio Insights
                </a>
              </h3>

              <Switch
                loading={loading}
                disabled={!event.isOwner || loading}
                checked={!event.excludedFromInsights}
                checkedChildren={<StarsIcn />}
                unCheckedChildren={<StarsIcn />}
                onChange={checked => handleInsightToggleChanged(!checked)}
              />
            </Detail>
          </>
        )}

        {event.status === 'READY' && (
          <>
            {divider}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => window.open(`/recap/${event.id}`)}>
                Open recap with full details
              </Button>
            </div>
          </>
        )}
      </Section>
    </Container>
  );
};
